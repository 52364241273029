import React, { useEffect, useState } from "react";
import axios from "axios";
import LayoutNew from "../../components/LayoutNew";
import { Helmet } from "react-helmet";

export default function StarCodes() {
  const [starCodes, setStarCodes] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(
          "https://product-catalog-storage.s3.ca-central-1.amazonaws.com/snapshots/latest/services/home-phones/star_codes.json"
        );
        setStarCodes(response.data);
      } catch (err) {
        console.error("Failed to fetch star codes:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <LayoutNew>
      <Helmet>
        <title>Star Codes – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
      }}>
        <h1>Home Phone Star Codes</h1>
        <p className="font-bold">Dial these star codes to enable or disable calling features.</p>
        <div className={"table-container"}>
          <table>
            <thead>
            <tr>
              <th>Code</th>
              <th>Title</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {starCodes.map((code) => (
                <tr key={code.id}>
                  <td>{code.code}</td>
                  <td>{code.title}</td>
                  <td>{code.description}</td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </section>
    </LayoutNew>
  );
}